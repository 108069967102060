<template>
  <div class="analytics">
    <div class="banner" v-if="userChoice === null">
      <div class="banner-inner">
        <div class="banner-text">
          <p>Cookies help us improve our services.</p>
        </div>
        <div class="banner-btns">
          <button type="button" class="decline btn" @click="rejectCookies">
            Decline cookies
          </button>
          <button type="button" class="accept btn" @click="acceptCookies">
            Accept cookies
          </button>
        </div>
        <button type="button" class="dismiss" @click="acceptCookies">
          <Icon name="fa6-solid:xmark" />
        </button>
      </div>
    </div>

    <button
      type="button"
      class="change-mind"
      @click="changeMind"
      v-if="userChoice !== null && userChoice !== undefined"
    >
      <Icon name="emojione:cookie" />
    </button>
  </div>
</template>

<script setup lang="ts">
import posthog from "posthog-js";

const runtimeConfig = useRuntimeConfig();
const userChoice: Ref<boolean | undefined | null> = ref();

// initialise the analytics
posthog.init(runtimeConfig.public.posthogPublicKey, {
  api_host: "https://watermelon.ansearch.net",
  secure_cookie: true,
  capture_pageview: true,
  // setup recoding
  session_recording: {
    maskInputOptions: {
      password: true,
      email: true,
      search: true,
      url: true,
      textarea: true,
    },
    maskTextSelector: "#sensitive",
  },
});

// verify the user choice
if (process.client) {
  loadChoice();
}

/**
 * posthog needs to be rested when the user change their mind.
 * This function indicate is the new choice correspond to the posthog value
 * NB: this function must be called before setting posthog new state
 * @param newChoice the new choice
 */
function reload(newChoice: boolean) {
  // if the user opted in, reload if the value is has_opted_out
  if (newChoice === true && posthog.has_opted_out_capturing()) {
    return true;
  }
  // if the user opted out, reload if the value is has_opted_in
  if (newChoice === false && posthog.has_opted_in_capturing()) {
    return true;
  }
  return false;
}

/**
 * Get the user choice from the local storage and set the user choice to true, false or null
 */
function loadChoice() {
  const storageValue = localStorage.getItem("cookieChoice");
  if (storageValue === null) {
    userChoice.value = null;
  } else {
    userChoice.value = storageValue === "true";
  }
}

// set the user choice
function rejectCookies() {
  // verify if need reload
  const shouldReload = reload(false);
  // set the values
  posthog.opt_out_capturing();
  localStorage.setItem("cookieChoice", String(false));
  userChoice.value = false;
  // then execute a reload if needed
  if (shouldReload) {
    location.reload();
  }
}

// set the user choice
function acceptCookies() {
  // verify if need reload
  const shouldReload = reload(true);
  // set the values
  posthog.opt_in_capturing();
  localStorage.setItem("cookieChoice", String(true));
  userChoice.value = true;
  // then execute a reload if needed
  if (shouldReload) {
    location.reload();
  }
}

// allow users to change their mind
function changeMind() {
  localStorage.removeItem("cookieChoice");
  userChoice.value = null;
}
</script>

<style scoped>
.analytics {
  position: fixed;
  z-index: 1;
  bottom: 0;
  display: flex;
  height: auto;
}

.banner {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.banner-inner {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  max-width: 90%;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid var(--seperator-colour);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-btns {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
}

.accept {
  background-color: rgb(42, 177, 42);
  color: white;
}
.decline {
  background-color: inherit;
  border: 1px solid var(--seperator-colour);
}

.dismiss {
  position: absolute;
  top: -10px;
  right: -10px;

  border: 1px solid var(--seperator-colour);
  border-radius: 50rem;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  color: white;
  cursor: pointer;
}

.change-mind {
  display: flex;
  font-size: 2.5rem;
  padding: 1rem;
  cursor: pointer;
}
</style>
